import React from 'react'
import { Link, navigate } from 'gatsby'
import { sessionKeys } from "../services/const"
import { getFormValues, postForm } from '../services/form'
import Seo from '../components/seo'

class ChangeInfoConfirm extends React.Component {

  handleSubmit = event => {
    event.preventDefault()
    const postData = getFormValues(sessionKeys.changeForm)
    if (postForm(process.env.API_GATEWAY_BASE_URL + "change_info", postData)) {
      navigate('/changeInfoComplete')
    }
    else {
      console.log('Server Error');
    }
  }

  handleBack = event => {
    event.preventDefault()
    navigate('/changeInfo')
  }

  render = () => {
    const changeForm = getFormValues(sessionKeys.changeForm)

    return (
      <>
        <Seo title="登録内容の変更" />
        <div id="pageTitle">
          <h1>
            登録内容の変更
          </h1>
        </div>
        <div className="breadClumb">
          <ul>
            <li><Link to="/">HOME</Link></li>
            <li><Link to="/member">入会のご案内</Link></li>
            <li>登録内容の変更</li>
          </ul>
        </div>
        <div id="main">
          <div className="contents">

            <section className="contents_block">
              <p>入力内容をご確認いただき、送信してください。</p>
            </section>

            <form name="" onSubmit={this.handleSubmit} >
              <section className="contents_block narrow mb50">
                <h2 className="header02 mb20">基本情報</h2>

                <div className="form_block">
                  <div className="form_head">
                    会員番号
                  </div>
                  <div className="form_body">
                    {changeForm.MemberNumber}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    お名前
                  </div>
                  <div className="form_body">
                    {changeForm.LastNameKanji} {changeForm.FirstNameKanji}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    お名前（カナ）
                  </div>
                  <div className="form_body">
                    <div className="form_body_parent">
                      {changeForm.LastNameKatakana} {changeForm.FirstNameKatakana}
                    </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    生年月日
                  </div>
                  <div className="formField">
                    {changeForm.BirthYear}年{changeForm.BirthMonth}月{changeForm.BirthDay}日
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    協会誌等送付先
                  </div>
                  <div className="form_body">
                    {changeForm.SendingAddress}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    年会費支払方法
                  </div>
                  <div className="form_body">
                    {changeForm.PaymentType}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    連絡先
                  </div>
                  <div className="form_body">
                    {changeForm.ContactAddress}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    メールアドレス
                  </div>
                  <div className="form_body">
                    {changeForm.MailAddress}
                  </div>
                </div>
              </section>

              <section className="contents_block narrow mb50">
                <h2 className="header02 mb20">勤務先情報</h2>
                <div className="form_block">
                  <div className="form_head">会社名</div>
                  <div className="form_body">
                    {changeForm.CompanyName}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">支社・支店・営業所名</div>
                  <div className="form_body">
                    {changeForm.BranchOffice}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">部署・課名</div>
                  <div className="form_body">
                    {changeForm.DeptName}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">役職名</div>
                  <div className="form_body">
                    {changeForm.JobTitle}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">郵便番号</div>
                  <div className="form_body">
                    <div className="form_body_child">
                      {changeForm.CompanyPostalCode}
                    </div>
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">所在地</div>
                  <div className="form_body">
                    {changeForm.CompanyAddress} <br />
                    {changeForm.CompanyAddressBuilding}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">電話番号（NTT）</div>
                  <div className="form_body">
                    {changeForm.TelephoneNumberNtt}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">FAX番号（NTT）</div>
                  <div className="form_body">
                    {changeForm.FaxNumberNtt}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">電話番号（JR）</div>
                  <div className="form_body">
                    {changeForm.TelephoneNumberJr}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">FAX番号（JR）</div>
                  <div className="form_body">
                    {changeForm.FaxNumberJr}
                  </div>
                </div>
              </section>

              <section className="contents_block narrow">
                <h2 className="header02 mb20">ご自宅の情報</h2>
                <div className="form_block">
                  <div className="form_head">
                    郵便番号
                  </div>
                  <div className="form_body">
                    {changeForm.PostalCode}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    住所
                  </div>
                  <div className="form_body">
                    {changeForm.Address} <br />
                    {changeForm.AddressBuilding}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    電話番号
                  </div>
                  <div className="form_body">
                    {changeForm.TelephoneNumber}
                  </div>
                </div>

                <div className="form_block">
                  <div className="form_head">
                    携帯電話
                  </div>
                  <div className="form_body">
                    {changeForm.MobilePhoneNumber}
                  </div>
                </div>
              </section>

              <section className="contents_block narrow">
                <h2 className="header02 mb20">その他の情報</h2>
                <div className="form_block">
                  <div className="form_head">通信欄</div>
                  <div className="form_body">
                    {changeForm.Message}
                  </div>
                </div>
              </section>
              <div className="aC"><input id="form_submit" type="submit" value="この内容で送信する" className="btn_rounded" /></div>
              <div className="aC mb50"><input type="button" value="戻る" className="btn_rounded keyColor08b" onClick={this.handleBack} /></div>
            </form>
          </div>
        </div>
      </>
    )
  }
}

export default ChangeInfoConfirm

